import {LineItemModel} from '../../../../domain/models/checkout/LineItem.model';
import {Violation} from '../../Violations/Violation';
import React from 'react';
import {useControllerProps} from '../../Widget/ControllerContext';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {ViolationModel} from '../../../../domain/models/checkout/Violation.model';
import {ItemOutOfStock} from '../../../../common/components/Item/ItemOutOfStock/ItemOutOfStock';
import {LineItemDataHooks} from './LineItem';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../../common/constants';
import {SuggestedFix} from '../../../../domain/models/checkout/ViolationLineItemTargetModel.model';

export const LineItemAdditionalInfo = ({
  lineItem,
  isItemOutOfStock,
  lineItemViolation,
}: {
  lineItem: LineItemModel;
  isItemOutOfStock: boolean;
  lineItemViolation?: ViolationModel;
}) => {
  const {
    checkoutStore: {removeLineItem, reportViolationRemoveButtonClick},
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();
  const removeLineItemFromCart = () => void removeLineItem(lineItem.id);

  const onViolationRemoveButtonClick = () => {
    removeLineItemFromCart();
    reportViolationRemoveButtonClick({
      message: lineItemViolation?.description,
      lineItemId: lineItem.id,
      suggestedFix: lineItemViolation?.target?.lineItemTarget?.suggestedFix,
    });
  };

  /* istanbul ignore else */
  if (isItemOutOfStock) {
    return (
      <ItemOutOfStock
        dataHook={LineItemDataHooks.OutOfStock}
        message={localeKeys.checkout.order_summary.item_out_of_stock_error()}
        removeButtonText={localeKeys.checkout.order_summary.removeFromCartError.cta()}
        removeButtonOnClick={removeLineItemFromCart}
      />
    );
  } else if (lineItemViolation) {
    const shouldShowRemoveButton =
      experiments.enabled(SPECS.SupportDeliveryViolationsOnCheckout) &&
      lineItemViolation.target?.lineItemTarget?.suggestedFix === SuggestedFix.removeLineItem;

    return (
      <Violation
        severity={lineItemViolation.severity}
        description={lineItemViolation.description}
        actionText={shouldShowRemoveButton ? localeKeys.checkout.order_summary.removeItem.cta() : undefined}
        onPerformAction={shouldShowRemoveButton ? onViolationRemoveButtonClick : undefined}
      />
    );
  }
  /* istanbul ignore next */
  return null;
};
